<template>
  <div style="width: 100%;height: 100vh;background-color: rgb(8, 20, 32)">
<!--    <div id="bj">-->
<!--      <img :src="bjsrc" style="width: 100%;height: 100%">-->
<!--    </div>-->
    <div style="position: absolute;left: 25%;top: 2%">
      <img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/show_progess.png" style="width: 80%">
    </div>

    <!--    头部-->
    <div style="height: 100px;display: flex;justify-content: space-between">
      <!--      时间-->
      <div id="timestyle">
        {{ timenow }}
      </div>

    </div>
    <div style="display: flex;height: 10px;justify-content: space-between">
      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-left: 50px;margin-top: -10px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>{{ message }}</span>
        <a href="https://www.cr12cz.cn/#/show_progess" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>
    </div>
    <div id="myEchartsAlone" style="width: 100%;height: 85%;"></div>
  </div>
</template>

<script>
import {setNowTimesInHole} from "@/utils/time";

export default {
  name: "show_alone",
  data(){
    return{
      bdst: '',
      tunnel: '',
      message: '',
      bjsrc: 'https://czsc.oss-cn-beijing.aliyuncs.com/bj/show_progess_bj.png',
      timenow: '',
      zf: ''
    }
  },
  created() {
    const url = window.location.href;             //获取当前url
    const dz_url = url.split('#')[1];    //获取#/之前的字符串
    const cs = dz_url.split('?')[1];     //获取?之后的参数字符串
    const cs_arr = cs.split('&');        //参数字符串分割为数组
    this.bdst = cs_arr[0].split("=")[1];
    this.tunnel = cs_arr[1].split("=")[1];
    if (this.tunnel === '0'){
      this.message = '正洞开挖进度';
      this.zf = 'Z';
    }
    if (this.tunnel === '1'){
      this.message = '辅助坑道开挖进度';
      this.zf = 'F';
    }
    setTimeout(this.getRateOfProgess, 100);
  },
  methods:{
    getRateOfProgess() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsAlone'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('https://www.gjegm.ltd/showdata/ShowProgess/getRateOfProgessAll', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
          }
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 米',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 15
                },
                top: 20,
                left: 150,
              }
            ],
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff',
                rotate: 90
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                data: y,
                type: 'bar',
                barWidth: 30,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#fff',
                        fontSize: 12,
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
       zf: this.zf
      })
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timenow = setNowTimesInHole(new Date())
    }, 1000)

  }
}
</script>

<style scoped>
#timestyle {
  color: #fff;
  margin-right: 30px;
  margin-top: 25px;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px 0px 20px;
  background-color: rgb(12, 37, 48);
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  border-radius: 10px;
  margin-left: 50px;
}
</style>